import React, { ReactNode, useState } from 'react';

import { useOrderImportB2cCreationData } from '@abb-emobility/oms/data-provider';
import {
	OrderImportB2cCustomerModel,
	OrderImportB2cModel,
	OrderImportCustomerSalutation,
	OrderImportEntryModel,
	OrderImportPaymentMode,
	OrderImportPaymentModel,
	OrderImportRecipientModel
} from '@abb-emobility/oms/domain-model';
import { scrollWindowToTop } from '@abb-emobility/shared/browser';
import { CurrencyCode } from '@abb-emobility/shared/currency';
import { CrudActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { createTimestampFromDate, ModelPrimaryKey, Mutable, Mutation } from '@abb-emobility/shared/domain-model-foundation';
import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';
import { LocalStorage } from '@abb-emobility/shared/local-storage';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonGroup,
	ButtonPrimary,
	CollectionItemContentSection,
	InputCheckbox,
	InputRadio,
	InputRadioGroup,
	InputSelect,
	InputSelectOption,
	InputText,
	ItemFlow,
	Notification,
	NotificationLevel,
	NotificationProps,
	SectionHeader,
	SubmissionStatus
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { orderImportInstallationAddresses } from '../../../data/OrderImportInstallationAddressModel.mock';
import { orderImportProductBundles } from '../../../data/OrderImportProductBundleModel.mock';

export function OrderB2cForm() {

	const l10n = useL10n();

	const namespace = 'order-import-b2c';

	const [orderCode, setOrderCode] = useState<Nullable<string>>(LocalStorage.read<string>('orderCode', { namespace }).get());
	const [productBundleId, setProductBundleId] = useState<Nullable<string>>(LocalStorage.read<string>('productBundleId', { namespace }).get());
	const [order, setOrder] = useState<Partial<OrderImportB2cModel>>(LocalStorage.read<Partial<OrderImportB2cModel>>('order', { namespace }).getOrDefault({}));
	const [phoneNumber, setPhoneNumber] = useState<Nullable<string>>(LocalStorage.read<string>('phoneNumber', { namespace }).get());
	const [installationAddressId, setInstallationAddressId] = useState<Nullable<ModelPrimaryKey>>(LocalStorage.read<ModelPrimaryKey>('installationAddressId', { namespace }).get());

	const [storeLocally, setStoreLocally] = useState<boolean>(true);

	const [submissionButtonState, setSubmissionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);
	const [notification, setNotification] = useState<Nullable<NotificationProps>>(null);

	const orderImportCreattionData = useOrderImportB2cCreationData();
	const submissionState = orderImportCreattionData.queryActionStatus();
	const submissionError = orderImportCreattionData.queryActionError();

	orderImportCreattionData.useActionStatusEffect([CrudActionStatus.CREATE_PENDING], () => {
		setSubmissionButtonState(SubmissionStatus.PENDING);
	}, false);

	orderImportCreattionData.useActionStatusEffect([CrudActionStatus.CREATE_SUCCESS], () => {
		setSubmissionButtonState(SubmissionStatus.DONE);
		setNotification({
			level: NotificationLevel.SUCCESS,
			heading: l10n.translate('omsOrderApp.orderForm.notification.success.caption'),
			message: l10n.translate('omsOrderApp.orderForm.notification.success.message', new Map([
				['orderCode', orderCode ?? ''],
				['abbOrderCode', createAbbOrderCode(orderCode ?? '')]
			]))
		});
		scrollWindowToTop();
	}, true);

	orderImportCreattionData.useActionStatusEffect([CrudActionStatus.CREATE_FAILED], () => {
		setSubmissionButtonState(SubmissionStatus.IDLE);
		if (submissionError?.status === 409) {
			setNotification({
				level: NotificationLevel.WARNING,
				heading: l10n.translate('omsOrderApp.orderForm.notification.duplicationFailure.caption'),
				message: l10n.translate('omsOrderApp.orderForm.notification.duplicationFailure.message', new Map([
					['orderId', orderCode ?? '']
				]))
			});
		} else {
			setNotification({
				level: NotificationLevel.ERROR,
				heading: l10n.translate('omsOrderApp.orderForm.notification.failure.caption'),
				message: l10n.translate('omsOrderApp.orderForm.notification.failure.message', new Map([
					['errorMessage', submissionError?.message ?? '']
				]))
			});
		}
		scrollWindowToTop();
	}, true);

	function enrichOrder(order: Mutation<OrderImportB2cModel>): Mutation<OrderImportB2cModel> {

		const customer: OrderImportB2cCustomerModel = {
			...order.customer as OrderImportB2cCustomerModel,
			customerId: 'aCustomer-' + orderCode
		};

		const installationAddress = orderImportInstallationAddresses.find((orderImportInstallationAddress) => {
			return orderImportInstallationAddress.id === installationAddressId;
		});

		const productBundle = orderImportProductBundles.find((orderImportProductBundle) => {
			return orderImportProductBundle.id === productBundleId;
		});

		const orderEntry: Mutation<OrderImportEntryModel> = {
			entryNumber: 1,
			quantity: 1,
			product: undefined,
			basePrice: undefined,
			taxAmount: undefined,
			totalPrice: undefined
		};
		if (productBundle !== undefined) {
			orderEntry.product = {
				id: productBundle.id,
				name: productBundle.name
			};

			let taxRate = 19;
			if (installationAddress !== undefined) {
				const taxRates = installationAddress.taxMatrix.b2c;
				taxRate = taxRates.goods;
				if (productBundle.includesServices) {
					taxRate = taxRates.goodsAndServices;
				}
			}
			orderEntry.basePrice = productBundle.price;
			orderEntry.taxAmount = Math.round(productBundle.price * taxRate) / 100;
			orderEntry.totalPrice = Math.round((orderEntry.basePrice + orderEntry.taxAmount) * 100) / 100;
		}

		const effectiveOrderCode = createAbbOrderCode(orderCode ?? '');

		const orderRecipient: OrderImportRecipientModel = {
			firstName: order.customer?.firstName,
			lastName: order.customer?.lastName,
			address1: installationAddress?.street ?? '',
			city: installationAddress?.city ?? '',
			region: installationAddress?.region ?? '',
			country: installationAddress?.country ?? IsoCountryCode.DE,
			postalCode: installationAddress?.postalCode ?? '',
			phoneNumber: phoneNumber ?? '',
			workPhoneNumber: phoneNumber ?? '',
			emailAddress: order.customer?.emailAddress
		};

		const richOrder = {
			...order,
			code: effectiveOrderCode,
			vendorCode: effectiveOrderCode,
			marketplace: 'smart-de',
			orderCreationDateGMT: createTimestampFromDate(new Date()),
			deliveryMode: 'standard-gross',
			currency: CurrencyCode.EUR,
			customer,
			voucherCode: '',
			orderEntries: [orderEntry],
			payment: {
				...order.payment,
				amount: orderEntry.totalPrice,
				currency: CurrencyCode.EUR,
				paymentReference: 'payment-' + orderCode,
				settlementReference: 'settlement-' + orderCode
			},
			shipTo: orderRecipient,
			billTo: orderRecipient,
			orderPrices: {
				totalPrice: orderEntry.totalPrice,
				totalPriceWithTax: orderEntry.totalPrice,
				totalTax: orderEntry.taxAmount,
				subTotal: orderEntry.totalPrice,
				deliveryCost: 0,
				subTotalWithoutQuoteDiscounts: orderEntry.totalPrice,
				totalDiscounts: 0
			},
			merchantCustomerId: '',
			status: '',
			statusDisplay: '',
			deliveryStatus: '',
			deliveryStatusDisplay: '',
			placedBy: '',
			purchaseOrderNumber: ''
		};

		return richOrder;
	}

	const orderIsValid = (_order: Mutation<OrderImportB2cModel>): _order is Mutable<OrderImportB2cModel> => {
		return true;
	};

	const createAbbOrderCode = (orderCode: string): string => {
		return 'B2C-' + orderCode;
	};

	const handleSubmission = (): void => {
		if (storeLocally) {
			LocalStorage.write('orderCode', orderCode, { namespace });
			LocalStorage.write('productBundleId', productBundleId, { namespace });
			LocalStorage.write('order', order, { namespace });
			LocalStorage.write('phoneNumber', phoneNumber, { namespace });
			LocalStorage.write('installationAddressId', installationAddressId, { namespace });
		} else {
			LocalStorage.remove('orderCode', { namespace });
			LocalStorage.remove('productBundleId', { namespace });
			LocalStorage.remove('order', { namespace });
			LocalStorage.remove('phoneNumber', { namespace });
			LocalStorage.remove('installationAddressId', { namespace });
		}

		const richOrder = enrichOrder(order);
		if (orderIsValid(richOrder)) {
			orderImportCreattionData.create(richOrder);
		}
	};

	const updateOrderCode = (code: string): void => {
		setOrderCode(code);
		setSubmissionButtonState(SubmissionStatus.IDLE);
	};

	const updateProductBundle = (bundleId: string): void => {
		setProductBundleId(bundleId);
		setSubmissionButtonState(SubmissionStatus.IDLE);
	};

	const updateCustomer = (key: keyof OrderImportB2cCustomerModel, value: string): void => {
		const customer = {
			...order.customer ?? {},
			[key]: value
		};
		setOrder({
			...order,
			customer: customer as OrderImportB2cCustomerModel
		});
		setSubmissionButtonState(SubmissionStatus.IDLE);
	};

	const updatePayment = (key: keyof OrderImportPaymentModel, value: string): void => {
		setOrder({
			...order,
			payment: {
				...order.payment as OrderImportPaymentModel,
				[key]: value
			}
		});
		setSubmissionButtonState(SubmissionStatus.IDLE);
	};

	const isoLanguageCodeOptions = Object.values(IsoLanguageCode).map<InputSelectOption<IsoLanguageCode>>((key) => {
		return {
			label: key,
			value: key
		};
	});

	const productBundleOptions = orderImportProductBundles.map<InputSelectOption>((bundle) => {
		return {
			label: bundle.name,
			value: bundle.id
		};
	});

	const installationAddressOptions = orderImportInstallationAddresses.map<InputSelectOption>((installationAddress) => {
		return {
			label: installationAddress.street + ', ' + installationAddress.postalCode + ' ' + installationAddress.city,
			value: installationAddress.id
		};
	});

	const renderNotification = (): ReactNode => {
		if (notification === null) {
			return null;
		}
		return (
			<Notification {...notification} dismissable={false} />
		);
	};

	return (
		<>
			{renderNotification()}

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsOrderApp.orderForm.orderMeta.caption')} />
				<InputText
					label={l10n.translate('omsOrderApp.orderForm.orderMeta.code')}
					defaultValue={orderCode ?? undefined}
					onChange={(value) => updateOrderCode(value)}
				/>
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsOrderApp.orderForm.customer.caption')} />
				<InputRadioGroup
					label={l10n.translate('omsOrderApp.orderForm.customer.salutation.label')}
					itemFlow={ItemFlow.HORIZONTAL}
				>
					<InputRadio
						label={l10n.translate(l10nLiteralFromEnumValue(OrderImportCustomerSalutation.MALE, 'omsOrderApp.orderForm.customer.salutation.options'))}
						name="customer.salutation"
						defaultValue={OrderImportCustomerSalutation.MALE}
						defaultChecked={order.customer?.salutation === OrderImportCustomerSalutation.MALE}
						onChange={() => updateCustomer('salutation', OrderImportCustomerSalutation.MALE)}
					/>
					<InputRadio
						label={l10n.translate(l10nLiteralFromEnumValue(OrderImportCustomerSalutation.FEMALE, 'omsOrderApp.orderForm.customer.salutation.options'))}
						name="customer.salutation"
						defaultValue={OrderImportCustomerSalutation.FEMALE}
						defaultChecked={order.customer?.salutation === OrderImportCustomerSalutation.FEMALE}
						onChange={() => updateCustomer('salutation', OrderImportCustomerSalutation.FEMALE)}
					/>
				</InputRadioGroup>
				<InputText
					label={l10n.translate('omsOrderApp.orderForm.customer.firstName')}
					defaultValue={order.customer?.firstName}
					onChange={(value) => updateCustomer('firstName', value)}
				/>
				<InputText
					label={l10n.translate('omsOrderApp.orderForm.customer.lastName')}
					defaultValue={order.customer?.lastName}
					onChange={(value) => updateCustomer('lastName', value)}
				/>
				<InputText
					label={l10n.translate('omsOrderApp.orderForm.customer.emailAddress')}
					defaultValue={order.customer?.emailAddress}
					onChange={(value) => updateCustomer('emailAddress', value)}
				/>
				<InputText
					label={l10n.translate('omsOrderApp.orderForm.customer.cellPhoneNumber')}
					defaultValue={phoneNumber ?? undefined}
					onChange={setPhoneNumber}
				/>
				<InputSelect<IsoLanguageCode>
					label={l10n.translate('omsOrderApp.orderForm.customer.languageCode')}
					value={order.customer?.languageCode}
					placeholder={l10n.translate('omsOrderApp.orderForm.customer.languageCodePlaceholder')}
					onSelect={(value) => updateCustomer('languageCode', value)}
					options={isoLanguageCodeOptions}
				/>
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsOrderApp.orderForm.productBundle.caption')} />
				<InputSelect
					label={l10n.translate('omsOrderApp.orderForm.productBundle.selection')}
					value={productBundleId ?? undefined}
					placeholder={l10n.translate('omsOrderApp.orderForm.productBundle.placeholder')}
					onSelect={updateProductBundle}
					options={productBundleOptions}
				/>
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsOrderApp.orderForm.payment.caption')} />
				<InputRadioGroup
					label={l10n.translate('omsOrderApp.orderForm.payment.label')}
					itemFlow={ItemFlow.HORIZONTAL}
				>
					<InputRadio
						label={l10n.translate(l10nLiteralFromEnumValue(OrderImportPaymentMode.CREDIT_CARD, 'omsOrderApp.orderForm.payment.options'))}
						name="payment"
						defaultValue={OrderImportPaymentMode.CREDIT_CARD}
						defaultChecked={order.payment?.mode === OrderImportPaymentMode.CREDIT_CARD}
						onChange={() => updatePayment('mode', OrderImportPaymentMode.CREDIT_CARD)}
					/>
					<InputRadio
						label={l10n.translate(l10nLiteralFromEnumValue(OrderImportPaymentMode.REQUEST_TO_PAY, 'omsOrderApp.orderForm.payment.options'))}
						name="payment"
						defaultValue={OrderImportPaymentMode.REQUEST_TO_PAY}
						defaultChecked={order.payment?.mode === OrderImportPaymentMode.REQUEST_TO_PAY}
						onChange={() => updatePayment('mode', OrderImportPaymentMode.REQUEST_TO_PAY)}
					/>
				</InputRadioGroup>
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsOrderApp.orderForm.installationAddress.caption')} />
				<InputSelect
					label={l10n.translate('omsOrderApp.orderForm.installationAddress.selection')}
					value={installationAddressId ?? undefined}
					placeholder={l10n.translate('omsOrderApp.orderForm.installationAddress.placeholder')}
					onSelect={setInstallationAddressId}
					options={installationAddressOptions}
				/>
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<InputCheckbox
					label={l10n.translate('omsOrderApp.orderForm.storeLocally')}
					value={1}
					defaultChecked={storeLocally}
					onChange={setStoreLocally}
				/>
			</CollectionItemContentSection>

			<ButtonGroup>
				<ButtonPrimary
					label={l10n.translate('omsOrderApp.orderForm.actions.placeOrder')}
					submissionStatus={submissionButtonState}
					onClick={handleSubmission}
					disabled={submissionState !== CrudActionStatus.IDLE}
				/>
			</ButtonGroup>

		</>
	);
}
