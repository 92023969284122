import React from 'react';

import { FooModel } from '@abb-emobility/oms/domain-model';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	createCrudEntityDataProviderValue,
	EntityDataProviderProps,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { withErrorHandler } from '@abb-emobility/shared/error';

import { fooEntityData } from './FooEntityData';
import { FooEntityStore, fooEntityStoreAccessors, fooEntityStoreName } from './FooEntitySlice';

export function FooEntityDataProvider(props: EntityDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		primaryKey,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch
	} = props;

	const auth = useAuth();
	const env = useEnv();

	const dataProviderValue = createCrudEntityDataProviderValue<FooModel, FooEntityStore>(
		fooEntityStoreName,
		fooEntityStoreAccessors,
		primaryKey,
		'https://foo.bar/',
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError('Error fetching foo'));

	useDataProviderFetchInit(
		fetchMode,
		() => dataProviderValue.fetch(forceFetch),
		() => dataProviderValue.resolveActionStatus()
	);

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<fooEntityData.Provider value={dataProviderValue}>
			{props.children}
		</fooEntityData.Provider>
	);

}

export const FooEntityDataProviderWithErrorHandler = withErrorHandler(FooEntityDataProvider);
