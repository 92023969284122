import Timezone from 'timezone-enum';

import { createDateFromMaybeTimestamp, Timestamp } from '@abb-emobility/shared/domain-model-foundation';
import { getMorningOfDay, getCalendarDay, getToday, Nullable, Optional } from '@abb-emobility/shared/util';

import { DaynameFormat, FuzzyDayname, MonthnameFormat, YearFormat } from './L10n.enums';
import { Locale } from './Locale';

export class L10n {

	private static locales: Record<string, Locale> = {};

	private static defaultLocaleIdentifier: string;

	private static selectedLocaleIdentifier: string;

	private static selectedTimezoneIdentifier: Nullable<Timezone> = null;

	public static addLocale(locale: Locale): void {
		this.locales[locale.identifier.toLowerCase()] = locale;
	}

	public static hasLocale(localeIdentifier: string): boolean {
		return localeIdentifier.toLowerCase() in this.locales;
	}

	public static getLocales(): Map<string, Locale> {
		return new Map(Object.entries(this.locales));
	}

	public static getLocale(localeIdentifier: string): Optional<Locale> {
		return new Optional<Locale>(this.locales?.[localeIdentifier.toLowerCase()] ?? null);
	}

	public static async setDefaultLocale(localeIdentifier: string): Promise<void> {
		if (!this.hasLocale(localeIdentifier)) {
			throw new Error('Locale with identifier ' + localeIdentifier + ' unknown');
		}
		this.defaultLocaleIdentifier = localeIdentifier.toLowerCase();
		await this.getLocale(this.defaultLocaleIdentifier).getOrUndefined()?.select();
	}

	public static async selectLocale(localeIdentifier: string): Promise<void> {
		if (!this.hasLocale(localeIdentifier)) {
			throw new Error('Locale with identifier ' + localeIdentifier + ' unknown');
		}
		this.selectedLocaleIdentifier = localeIdentifier.toLowerCase();
		await this.getLocale(this.selectedLocaleIdentifier).getOrUndefined()?.select();
	}

	public static async detectLocale(): Promise<boolean> {
		const fallbackLanguages: Array<string> = [];
		for (let language of this.getNavigatorPreferredLanguages()) {
			language = language.toLowerCase();
			if (language in this.locales) {
				await this.selectLocale(language);
				return true;
			}
			if (language.includes('-')) {
				fallbackLanguages.push(language.substring(0, language.indexOf('-')));
			}
		}

		for (const language of fallbackLanguages) {
			if (language in this.locales) {
				await this.selectLocale(language);
				return true;
			}
		}

		return false;
	}

	public static selectedLocale(): Nullable<string> {
		return this.selectedLocaleIdentifier ?? null;
	}

	public static effectiveLocale(): Nullable<string> {
		return this.selectedLocaleIdentifier ?? this.defaultLocaleIdentifier ?? null;
	}

	public static autoSelectTimezone(): void {
		this.selectedTimezoneIdentifier = null;
	}

	public static selectTimezone(timezone: Timezone): void {
		this.selectedTimezoneIdentifier = timezone;
	}

	public static selectedTimezone(): Nullable<Timezone> {
		return this.selectedTimezoneIdentifier;
	}

	public static translate(literal: string, replacements?: Map<string, string> | Record<string, string>, defaultValue?: string): string {
		const effectiveLocale = this.getEffectiveLocale();
		if (effectiveLocale === null) {
			return defaultValue ?? literal;
		}
		return effectiveLocale.translate(literal, replacements, defaultValue) ?? literal;
	}

	public static formatDate(date?: Date, defaultLocale?: string, defaultValue?: string): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		let effectiveLocale = this.effectiveLocale();
		if (effectiveLocale === null) {
			effectiveLocale = defaultLocale ? defaultLocale : null;
		}
		return date.toLocaleDateString(effectiveLocale ?? undefined, {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
			timeZone: this.selectedTimezone() ?? undefined
		});
	}

	public static formatTimestampDate(timestamp?: Timestamp, defaultLocale?: string, defaultValue?: string): string {
		return this.formatDate(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), defaultLocale, defaultValue);
	}

	public static formatTime(date?: Date, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		let effectiveLocale = this.effectiveLocale();
		if (effectiveLocale === null) {
			effectiveLocale = defaultLocale ? defaultLocale : null;
		}
		return date.toLocaleTimeString(effectiveLocale ?? undefined, {
			hour: '2-digit',
			minute: '2-digit',
			second: includeSeconds ? '2-digit' : undefined,
			hour12: false,
			timeZone: this.selectedTimezone() ?? undefined
		});
	}

	public static formatTimestampTime(timestamp?: Timestamp, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string {
		return this.formatTime(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), includeSeconds, defaultLocale, defaultValue);
	}

	public static formatDateTime(date?: Date, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		return this.formatDate(date, defaultLocale) + ' ' + this.formatTime(date, includeSeconds, defaultLocale);
	}

	public static formatTimestampDateTime(timestamp?: Timestamp, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string {
		return this.formatDateTime(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), includeSeconds, defaultLocale, defaultValue);
	}

	public static formatDayname(date?: Date, defaultValue?: string, format: DaynameFormat = DaynameFormat.LONG): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		if (format === DaynameFormat.FUZZY) {
			const day = getMorningOfDay(date);
			const today = getToday();
			const yesterday = getCalendarDay(-1);
			const tomorrow = getCalendarDay(1);
			if (day.getTime() === yesterday.getTime()) {
				return FuzzyDayname.YESTERDAY;
			}
			if (day.getTime() === today.getTime()) {
				return FuzzyDayname.TODAY;
			}
			if (day.getTime() === tomorrow.getTime()) {
				return FuzzyDayname.TOMORROW;
			}
			format = DaynameFormat.LONG;
		}
		const dayName = date.toLocaleString(
			this.effectiveLocale() ?? undefined,
			{
				timeZone: this.selectedTimezone() ?? undefined,
				weekday: format
			}
		);
		return dayName;
	}

	public static formatTimestampDayname(timestamp?: Timestamp, defaultValue?: string, format: DaynameFormat = DaynameFormat.LONG): string {
		return this.formatDayname(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), defaultValue, format);
	}

	public static formatMonthname(date?: Date, defaultValue?: string, format: MonthnameFormat = MonthnameFormat.LONG): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		const monthName = date.toLocaleString(
			this.effectiveLocale() ?? undefined,
			{
				timeZone: this.selectedTimezone() ?? undefined,
				month: format
			}
		);
		return monthName;
	}

	public static formatTimestampMonthname(timestamp?: Timestamp, defaultValue?: string, format: MonthnameFormat = MonthnameFormat.LONG): string {
		return this.formatMonthname(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), defaultValue, format);
	}

	public static formatYear(date?: Date, defaultValue?: string, format: YearFormat = YearFormat.FULL): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		const yearName = date.toLocaleString(
			this.effectiveLocale() ?? undefined,
			{
				timeZone: this.selectedTimezone() ?? undefined,
				year: format
			}
		);
		return yearName;
	}

	public static formatTimestampYear(timestamp?: Timestamp, defaultValue?: string, format?: YearFormat): string {
		return this.formatYear(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), defaultValue, format);
	}

	public static formatNumber(number?: number, decimals = 2, defaultLocale?: string, defaultValue?: string): string {
		if (number === undefined || isNaN(number)) {
			return defaultValue ?? '';
		}
		let effectiveLocale = this.effectiveLocale();
		if (effectiveLocale === null) {
			effectiveLocale = defaultLocale ? defaultLocale : null;
		}
		return number.toLocaleString(effectiveLocale ?? undefined, {
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals
		});
	}

	private static getNavigatorPreferredLanguages(): ReadonlyArray<string> {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return navigator.languages ? navigator.languages : ([navigator.language] || [navigator.userLanguage]);
	}

	private static getEffectiveLocale(): Nullable<Locale> {
		let effectiveLocale = this.locales[this.selectedLocaleIdentifier] ?? null;
		if (effectiveLocale === null) {
			effectiveLocale = this.locales[this.defaultLocaleIdentifier] ?? null;
		}
		return effectiveLocale;
	}

}
