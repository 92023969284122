/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, FC } from 'react';

import { Logger } from './Logger';
import { useLogger } from './provider/LoggerConsumer';

type ComponentProps = {
	logger?: Logger
} & any;

export const withLogger = <P = ComponentProps>(Component: ComponentType<P>): FC<P> => {
	return (props: any) => {
		const logger = useLogger();
		return <Component logger={logger ?? undefined} {...props} />;
	};
};
