import { OrderImportProductBundleModel } from '@abb-emobility/oms/domain-model';

const orderImportProductBundleABB6AGC113080: OrderImportProductBundleModel = {
	id: 'ABB6AGC113080',
	name: 'TAC-W22-G5-R-Smart',
	price: 577.82,
	includesServices: false
};

const orderImportProductBundleABB6AGC113080S: OrderImportProductBundleModel = {
	id: 'ABB6AGC113080S',
	name: 'TAC-W22-G5-R-Smart-SIP',
	price: 1707.78,
	includesServices: true
};

const orderImportProductBundleABB6AGC113080P: OrderImportProductBundleModel = {
	id: 'ABB6AGC113080P',
	name: 'TAC-W22-G5-R-Smart-PED',
	price: 735.74,
	includesServices: false
};

const orderImportProductBundleABB6AGC113080PS: OrderImportProductBundleModel = {
	id: 'ABB6AGC113080PS',
	name: 'TAC-W22-G5-R-Smart-PED-SIP',
	price: 1957.96,
	includesServices: true
};

const orderImportProductBundleABB6AGC113082: OrderImportProductBundleModel = {
	id: 'ABB6AGC113082',
	name: 'TAC-W22-T-R-Smart',
	price: 485.56,
	includesServices: false
};

const orderImportProductBundleABB6AGC113082S: OrderImportProductBundleModel = {
	id: 'ABB6AGC113082S',
	name: 'TAC-W22-T-R-Smart-SIP',
	price: 1707.78,
	includesServices: true
};

const orderImportProductBundleABB6AGC113082P: OrderImportProductBundleModel = {
	id: 'ABB6AGC113082P',
	name: 'TAC-W22-T-R-Smart-PED',
	price: 735.74,
	includesServices: false
};

const orderImportProductBundleABB6AGC113082PS: OrderImportProductBundleModel = {
	id: 'ABB6AGC113082PS',
	name: 'TAC-W22-T-R-Smart-PED-SIP',
	price: 1957.96,
	includesServices: true
};

const orderImportProductBundleABB6AGC113084: OrderImportProductBundleModel = {
	id: 'ABB6AGC113084',
	name: 'TAC-W22-G5-RD-MC-Smart',
	price: 578.14,
	includesServices: false
};

const orderImportProductBundleABB6AGC113084S: OrderImportProductBundleModel = {
	id: 'ABB6AGC113084S',
	name: 'TAC-W22-G5-RD-MC-Smart-SIP',
	price: 1800.36,
	includesServices: true
};

const orderImportProductBundleABB6AGC113084P: OrderImportProductBundleModel = {
	id: 'ABB6AGC113084P',
	name: 'TAC-W22-G5-RD-MC-Smart-PED',
	price: 828.32,
	includesServices: false
};

const orderImportProductBundleABB6AGC113084PS: OrderImportProductBundleModel = {
	id: 'ABB6AGC113084PS',
	name: 'TAC-W22-G5-RD-MC-Smart-PED-SIP',
	price: 2050.54,
	includesServices: true
};

const orderImportProductBundleABB6AGC113086: OrderImportProductBundleModel = {
	id: 'ABB6AGC113086',
	name: 'TAC-W22-T-RD-MC-Smart',
	price: 578.14,
	includesServices: false
};

const orderImportProductBundleABB6AGC113086S: OrderImportProductBundleModel = {
	id: 'ABB6AGC113086S',
	name: 'TAC-W22-T-RD-MC-Smart-SIP',
	price: 1800.36,
	includesServices: true
};

const orderImportProductBundleABB6AGC113086P: OrderImportProductBundleModel = {
	id: 'ABB6AGC113086P',
	name: 'TAC-W22-T-RD-MC-Smart-PED',
	price: 828.32,
	includesServices: false
};

const orderImportProductBundleABB6AGC113086PS: OrderImportProductBundleModel = {
	id: 'ABB6AGC113086PS',
	name: 'TAC-W22-T-RD-MC-Smart-PED-SIP',
	price: 2050.54,
	includesServices: true
};

export const orderImportProductBundles: Array<OrderImportProductBundleModel> = [
	orderImportProductBundleABB6AGC113080,
	orderImportProductBundleABB6AGC113080S,
	orderImportProductBundleABB6AGC113080P,
	orderImportProductBundleABB6AGC113080PS,
	orderImportProductBundleABB6AGC113082,
	orderImportProductBundleABB6AGC113082S,
	orderImportProductBundleABB6AGC113082P,
	orderImportProductBundleABB6AGC113082PS,
	orderImportProductBundleABB6AGC113084,
	orderImportProductBundleABB6AGC113084S,
	orderImportProductBundleABB6AGC113084P,
	orderImportProductBundleABB6AGC113084PS,
	orderImportProductBundleABB6AGC113086,
	orderImportProductBundleABB6AGC113086S,
	orderImportProductBundleABB6AGC113086P,
	orderImportProductBundleABB6AGC113086PS
];
