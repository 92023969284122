import { merge } from 'lodash-es';

import { isString, Nullable, Timeout } from '@abb-emobility/shared/util';

import { flattenLiteralStruct } from './Locale.util';

type LiteralPrimitives = string;
type LiteralStructValue =
	LiteralPrimitives
	| ReadonlyArray<LiteralPrimitives>
	| { [x: string]: LiteralStructValue }
	| ReadonlyArray<LiteralStructValue>;
export type LiteralStruct = Record<string, LiteralStructValue>;

export type LiteralBundleUrl = string;

export class Locale {

	public readonly identifier: string;
	private readonly literalDefinitions: Array<LiteralStruct | LiteralBundleUrl>;
	private warmed = false;
	private literals: Record<string, string> = {};

	constructor(identifier: string, literalsDefinitions: Array<LiteralStruct | LiteralBundleUrl>) {
		this.identifier = identifier;
		this.literalDefinitions = literalsDefinitions;
	}

	public async select(): Promise<void> {
		if (this.warmed) {
			return;
		}
		const literalDefinitions = (await Promise.all(
			this.literalDefinitions.map(async (literalDefinition) => {
				if (isString(literalDefinition)) {
					return this.loadLiterals(literalDefinition);
				}
				return literalDefinition;
			})
		)).filter((literalDefinition): literalDefinition is LiteralStruct => {
			return literalDefinition !== null;
		});
		const mergedLiterals = {};
		merge(mergedLiterals, ...literalDefinitions);
		this.literals = flattenLiteralStruct(mergedLiterals);
		this.warmed = true;
	}

	private async loadLiterals(url: LiteralBundleUrl): Promise<Nullable<LiteralStruct>> {
		try {
			const abortController = new AbortController();
			const request = new Request(
				url,
				{
					signal: abortController.signal,
					method: 'GET',
					cache: 'no-cache',
					headers: {
						'accept': 'application/json',
						'content-type': 'application/json'
					}
				}
			);
			const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error('Request timeout'), (): void => {
				abortController.abort();
			});
			return await response.json() as LiteralStruct;
		} catch (e) {
			console.warn(e);
			return null;
		}
	}

	public addLiterals(literals: LiteralStruct, prefix?: string) {
		const keyChain = prefix ? [prefix] : [];
		const flattenedLiterals = flattenLiteralStruct(literals, keyChain);
		this.literals = { ...this.literals, ...flattenedLiterals };
	}

	public translate(
		literal: string,
		replacements?: Map<string, string> | Record<string, string>,
		defaultValue: Nullable<string> = null
	): string | null {
		let result: Nullable<string>;
		if (literal in this.literals) {
			result = this.literals[literal];
		} else {
			result = defaultValue;
		}

		if (result === null) {
			return null;
		}

		if (replacements !== undefined) {
			const replacementMap = (replacements instanceof Map) ? replacements : new Map(Object.entries(replacements));
			replacementMap.forEach((value, key) => {
				result = (result as string).replace('{{' + key + '}}', value);
			});
		}
		return result;
	}

}
