import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import { AuthProvider, AuthProviderInterceptingProps } from '@abb-emobility/shared/auth-provider';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError, ErrorHandler } from '@abb-emobility/shared/error';
import { KeycloakApiClientFactory } from '@abb-emobility/shared/keycloak-integration';
import { OauthEmitterFactory } from '@abb-emobility/shared/ui-auth-emitter';
import { ScrollToTop, ToastManager } from '@abb-emobility/shared/ui-primitive';
import { Optional } from '@abb-emobility/shared/util';

import { AppShell } from './components/app-shell/AppShell';
import { Router } from './router/Router';
import { DefaultErrorView } from './views/default-error-view/DefaultErrorView';
import { UNAUTHENTICATE_SIGNAL } from '../store/store';

export function App() {

	const env = useEnv();
	const dispatch = useDispatch();

	const handleReauthenticate = () => {
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
	};

	const handleUnauthenticate = () => {
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
		try {
			const oauthBaseUrl = new Optional(process.env['NX_KEYCLOAK_API_BASE_URL'])
				.getOrThrow(new AppError('Oauth base URL unavailable'));
			const oauthRealm = env.get<string>('oauthRealm').getOrThrow(new AppError('Oauth realm unavailable'));
			KeycloakApiClientFactory.create().logout(oauthBaseUrl, oauthRealm);
		} catch (e) {
			console.warn(e);
		}
	};

	const renderAuthEmitter = (): ReactElement<AuthProviderInterceptingProps> => {
		return (<OauthEmitterFactory />);
	};

	return (
		<ErrorHandler errorComponent={DefaultErrorView}>
			<AuthProvider
				interceptingComponent={renderAuthEmitter}
				onReauthenticate={handleReauthenticate}
				onUnauthenticate={handleUnauthenticate}
				authScope={new Optional(process.env['NX_OAUTH_SCOPE']).getOrUndefined()}
			>
				<ToastManager>
					<AppShell>
						<Router />
					</AppShell>
				</ToastManager>
			</AuthProvider>
			<ScrollToTop />
		</ErrorHandler>
	);
}

export default App;
