import { L10nContextValue, useL10n } from '@abb-emobility/shared/localization-provider';

const composePageTitle = (l10n: L10nContextValue, pageTitleLiteral: string, replacements?: Map<string, string>) => {
	let title = l10n.translate(pageTitleLiteral, replacements);
	title += ' – ';
	title += l10n.translate('omsOrderApp.appName');
	return title;
};

export const useBuildPageTitle = (pageTitleLiteral: string, replacements?: Map<string, string>): string => {
	const l10n = useL10n();
	return composePageTitle(l10n, pageTitleLiteral, replacements);
};

export const usePageTitleBuilder = (): (pageTitleLiteral: string, replacements?: Map<string, string>) => string => {
	const l10n = useL10n();
	return (pageTitleLiteral: string, replacements?: Map<string, string>): string => {
		return composePageTitle(l10n, pageTitleLiteral, replacements);
	};
};
