import { isArray } from './Array';
import { trimFromLeft, trimFromRight } from './String';

export type ClassMap = Record<string, unknown>;

export const buildCssClassNamesFromClassMap = (classMap: ClassMap): Array<string> => {
	const classNames: Array<string> = [];
	for (const className in classMap) {
		const classMapElement = classMap[className];
		const checkValue = isArray(classMapElement) && classMapElement.length === 0 ? false : classMapElement;
		if ((checkValue ?? false) !== false) {
			classNames.push(className);
		}
	}
	return classNames;
};

export const buildCssClassStringFromClassMap = (classMap: ClassMap): string => {
	return buildCssClassNamesFromClassMap(classMap).join(' ');
};

export const buildCssClassNameFromEnumValue = (enumValue: string, prefix?: string, suffix?: string): string => {
	prefix = prefix ? trimFromRight(prefix, '-') + '--' : '';
	suffix = suffix ? '-' + trimFromLeft(suffix, '-') : '';
	return prefix + enumValue.toLowerCase().replace(/_/g, '-') + suffix;
};

export const extendCssMapByEnumValue = (classMap: ClassMap, enumValue: string, prefix?: string, suffix?: string): void => {
	classMap[buildCssClassNameFromEnumValue(enumValue, prefix, suffix)] = true;
};
