import { LocalStorage } from '@abb-emobility/shared/local-storage';
import { Nullable, Optional } from '@abb-emobility/shared/util';

import { Identity } from '../foundation/Identity';

export const IDENTITY_STORAGE_KEY = 'lib_identity';

export class IdentityHandler {

	private static instance: Nullable<IdentityHandler> = null;
	public scope?: string;

	private constructor(scope?: string) {
		this.scope = scope;
	}

	static get(scope?: string): IdentityHandler {
		if (this.instance === null) {
			this.instance = new this(scope);
		}
		this.instance.scope = scope;
		return this.instance;
	}

	setIdentity(identity: Identity): void {
		LocalStorage.write<Identity>(IDENTITY_STORAGE_KEY, identity, { namespace: this.scope });
	}

	unsetIdentity(): void {
		LocalStorage.remove(IDENTITY_STORAGE_KEY, { namespace: this.scope });
	}

	getIdentity(): Optional<Identity> {
		return LocalStorage.read<Identity>(IDENTITY_STORAGE_KEY, { namespace: this.scope });
	}

}
