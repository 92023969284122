export const isArray = (value: unknown): value is Array<unknown> => {
	return Array.isArray(value);
};

export const isOptionalArray = (value: unknown): value is Array<unknown> | undefined => {
	return value === undefined || isArray(value);
};

export const arrayPad = <T = unknown>(array: Array<T>, length: number, fill: T): Array<T> => {
	if (array.length >= length) {
		return array;
	}
	return Array.from({ ...array, length: length }, (v): T => v ?? fill);
};

export const arrayReverse = <T = unknown>(array: Array<T>): Array<T> => {
	return [...array].reverse();
};

export const uniqueFilter = <T = unknown>(value: T, index: number, array: Array<T>) => {
	return array.indexOf(value) === index;
};
