import { ReactNode, useEffect, useMemo, useState } from 'react';

import { Timestamp } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable, Optional } from '@abb-emobility/shared/util';

import { l10nContext } from './L10nContext';
import { L10n } from '../foundation/L10n';
import { DaynameFormat, MonthnameFormat, YearFormat } from '../foundation/L10n.enums';
import { Locale } from '../foundation/Locale';

export type L10nProviderProps = {
	children: ReactNode,
	locales: Array<Locale>,
	fallbackLocaleIdentifier: string
};

export const L10nProvider = (props: L10nProviderProps): ReactNode => {
	const { children, locales, fallbackLocaleIdentifier } = props;

	locales.forEach((locale) => {
		L10n.addLocale(locale);
	});

	const [locale, setLocale] = useState<Nullable<string>>(null);

	useMemo(() => {
		if (locale !== null) {
			return;
		}
		const selectInitialLocale = async () => {
			if (!await L10n.detectLocale()) {
				await L10n.selectLocale(fallbackLocaleIdentifier);
			}
			setLocale(L10n.selectedLocale());
		};
		void selectInitialLocale();
	}, [locale]);

	useEffect(() => {
		if (locale === null) {
			return;
		}
		window.document.documentElement.lang = locale;
	}, [locale]);

	if (locale === null) {
		return null;
	}

	const getLocales = (): Map<string, Locale> => {
		return L10n.getLocales();
	};

	const getLocale = (language: string): Optional<Locale> => {
		return L10n.getLocale(language);
	};

	const translate = (literal: string, replacements?: Map<string, string>, defaultValue?: string): string => {
		return L10n.translate(literal, replacements, defaultValue);
	};

	const formatDate = (date?: Date, defaultLocale?: string, defaultValue?: string): string => {
		return L10n.formatDate(date, defaultLocale, defaultValue);
	};

	const formatTimestampDate = (timestamp?: Timestamp, defaultLocale?: string, defaultValue?: string): string => {
		return L10n.formatTimestampDate(timestamp, defaultLocale, defaultValue);
	};

	const formatTime = (date?: Date, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string => {
		return L10n.formatTime(date, includeSeconds, defaultLocale, defaultValue);
	};

	const formatTimestampTime = (timestamp?: Timestamp, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string => {
		return L10n.formatTimestampTime(timestamp, includeSeconds, defaultLocale, defaultValue);
	};

	const formatDateTime = (date?: Date, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string => {
		return L10n.formatDateTime(date, includeSeconds, defaultLocale, defaultValue);
	};

	const formatTimestampDateTime = (timestamp?: Timestamp, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string => {
		return L10n.formatTimestampDateTime(timestamp, includeSeconds, defaultLocale, defaultValue);
	};

	const formatDayname = (date?: Date, defaultValue?: string, format: DaynameFormat = DaynameFormat.LONG): string => {
		return L10n.formatDayname(date, defaultValue, format);
	};

	const formatTimestampDayname = (timestamp?: Timestamp, defaultValue?: string, format: DaynameFormat = DaynameFormat.LONG): string => {
		return L10n.formatTimestampDayname(timestamp, defaultValue, format);
	};

	const formatMonthname = (date?: Date, defaultValue?: string, format: MonthnameFormat = MonthnameFormat.LONG): string => {
		return L10n.formatMonthname(date, defaultValue, format);
	};

	const formatTimestampMonthname = (timestamp?: Timestamp, defaultValue?: string, format: MonthnameFormat = MonthnameFormat.LONG): string => {
		return L10n.formatTimestampMonthname(timestamp, defaultValue, format);
	};

	const formatYear = (timestamp?: Date, defaultValue ?: string, format?: YearFormat): string => {
		return L10n.formatYear(timestamp, defaultValue, format);
	};

	const formatTimestampYear = (timestamp?: Timestamp, defaultValue ?: string, format?: YearFormat): string => {
		return L10n.formatTimestampYear(timestamp, defaultValue, format);
	};

	const formatNumber = (number?: number, decimals = 2, defaultLocale?: string, defaultValue = ''): string => {
		return L10n.formatNumber(number, decimals, defaultLocale, defaultValue);
	};

	const formatBoolean = (boolean?: boolean): string => {
		return boolean === true ? translate('common.inputs.boolean.true', undefined, 'true') : translate('common.inputs.boolean.false', undefined, 'false');
	};

	const changeLanguage = async (language: string): Promise<void> => {
		if (language.toLowerCase() === L10n.selectedLocale()) {
			return;
		}
		await L10n.selectLocale(language);
		setLocale(L10n.selectedLocale());
	};

	const provider = {
		language: locale,
		getLocales,
		getLocale,
		translate,
		formatDate,
		formatTimestampDate,
		formatTime,
		formatTimestampTime,
		formatDateTime,
		formatTimestampDateTime,
		formatDayname,
		formatTimestampDayname,
		formatMonthname,
		formatTimestampMonthname,
		formatYear,
		formatTimestampYear,
		formatNumber,
		formatBoolean,
		changeLanguage
	};

	return (
		<l10nContext.Provider value={provider}>
			{children}
		</l10nContext.Provider>
	);

};
