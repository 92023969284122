import React from 'react';

import { AnyInstallationPartnerOrderInformationCollectionItemModel } from '@abb-emobility/oms/domain-model';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError, withErrorHandler } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { installationPartnerOrderInformationCollectionData } from './InstallationPartnerOrderInformationCollectionData';
import { createFilterCriteria, createSortCriteria } from './InstallationPartnerOrderInformationCollectionDataProvider.util';
import {
	InstallationPartnerOrderInformationCollectionCrudStore,
	installationPartnerOrderInformationCollectionStoreAccessors,
	installationPartnerOrderInformationCollectionStoreName
} from './InstallationPartnerOrderInformationCollectionSlice';

type InstallationPartnerOrderInformationCollectionDataProviderProps =
	Omit<CollectionDataProviderProps<AnyInstallationPartnerOrderInformationCollectionItemModel>, 'filterCriteria' | 'sortCriteria'>
	& { orderId: ModelPrimaryKey };

export function InstallationPartnerOrderInformationCollectionDataProvider(
	props: InstallationPartnerOrderInformationCollectionDataProviderProps
) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch,
		orderId
	} = props;

	const env = useEnv();
	const auth = useAuth();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_WALLBOX_INSTALLATION_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<
		AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore
	>(
		installationPartnerOrderInformationCollectionStoreName,
		installationPartnerOrderInformationCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(
		dataProviderValue, new FetchError(l10n.translate('omsDataProvider.error.orderInformationCollectionFetchMessage'))
	);

	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(createSortCriteria(), createFilterCriteria(orderId), forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<installationPartnerOrderInformationCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</installationPartnerOrderInformationCollectionData.Provider>
	);

}

export const InstallationPartnerOrderInformationCollectionDataProviderWithErrorHandler = withErrorHandler(
	InstallationPartnerOrderInformationCollectionDataProvider
);
