import { CustomerOrderInformationOrderItemsInformationOrderLineItemModel } from '@abb-emobility/oms/domain-model';
import { createTimestampDtoFromDate, Dto } from '@abb-emobility/shared/domain-model-foundation';

export const customerOrderInformationOrderItemsInformationLineItemModelMock01: Dto<CustomerOrderInformationOrderItemsInformationOrderLineItemModel> = {
	id: '1',
	productName: 'Extended warranty 2 years',
	cancelled: false
};

export const customerOrderInformationOrderItemsInformationLineItemModelMock02: Dto<CustomerOrderInformationOrderItemsInformationOrderLineItemModel> = {
	id: '2',
	productName: 'Terra AC Wallbox, IP54',
	cancelled: false
};

export const customerOrderInformationOrderItemsInformationLineItemModelMock03: Dto<CustomerOrderInformationOrderItemsInformationOrderLineItemModel> = {
	id: '3',
	productName: 'TAC pedestal single-wallbox',
	cancelled: true,
	cancelledAt: createTimestampDtoFromDate(new Date('2022-12-20T16:48:00Z'))
};
