import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import {
	installationTradeModelMock01,
	installationTradeModelMock02,
	installationTradeModelMock03,
	installerModelMock01
} from '@abb-emobility/shared/api-integration';
import { InstallationPeriod, InstallationType } from '@abb-emobility/shared/domain-model';
import { Dto, createDateRangeDtoFromDates } from '@abb-emobility/shared/domain-model-foundation';
import { IdentityHandler } from '@abb-emobility/shared/identity-provider';

import { installationPartnerOrderModelMock } from './InstallationPartnerOrderModel.mock';

const installerIdentity = IdentityHandler.get().getIdentity().get();

let period = createDateRangeDtoFromDates(new Date('2024-02-29T10:00:00.000Z'), new Date('2024-02-29T16:30:00.000Z'))
	.getOrThrow(new Error('Could not create date range for mock data'));
export const installerAppointmentModelMock01: Dto<InstallerAppointmentModel> = {
	id: '1',
	orderId: installationPartnerOrderModelMock().id,
	installer: {
		...installerModelMock01,
		id: installerIdentity?.userId ?? '1',
		userName: installerIdentity?.userName ?? 'John Doe'
	},
	trade: installationTradeModelMock01,
	completionTaskId: 'ip7',
	period: period,
	periodStartsAt: period.start,
	customerFirstName: 'Max',
	customerLastName: 'Mustermann',
	customerCompanyName: 'Musterfirma',
	installationAddress: 'Musterstraße 234, 24145 Kiel',
	installationType: InstallationType.DELTA,
	installationPeriod: InstallationPeriod.PREPARATION
};

period = createDateRangeDtoFromDates(new Date('2024-03-12T10:00:00.000Z'), new Date('2024-03-12T16:45:00.000Z'))
	.getOrThrow(new Error('Could not create date range for mock data'));
export const installerAppointmentModelMock02: Dto<InstallerAppointmentModel> = {
	id: '2',
	orderId: installationPartnerOrderModelMock().id,
	installer: {
		...installerModelMock01,
		id: installerIdentity?.userId ?? '1',
		userName: installerIdentity?.userName ?? 'John Doe'
	},
	trade: installationTradeModelMock02,
	completionTaskId: 'ip7',
	period: period,
	periodStartsAt: period.start,
	customerFirstName: 'Max',
	customerLastName: 'Mustermann',
	customerCompanyName: 'Musterfirma',
	installationAddress: 'Musterstraße 234, 24145 Kiel',
	installationType: InstallationType.DELTA,
	installationPeriod: InstallationPeriod.PERMANENT
};

period = createDateRangeDtoFromDates(new Date('2024-03-28T10:00:00.000Z'), new Date('2024-03-28T16:45:00.000Z'))
	.getOrThrow(new Error('Could not create date range for mock data'));
export const installerAppointmentModelMock03: Dto<InstallerAppointmentModel> = {
	id: '3',
	orderId: installationPartnerOrderModelMock().id,
	installer: {
		...installerModelMock01,
		id: installerIdentity?.userId ?? '1',
		userName: installerIdentity?.userName ?? '1'
	},
	trade: installationTradeModelMock03,
	completionTaskId: 'ip7',
	period: period,
	periodStartsAt: period.start,
	customerFirstName: 'Max',
	customerLastName: 'Mustermann',
	customerCompanyName: 'Musterfirma',
	installationAddress: 'Musterstraße 234, 24145 Kiel',
	installationType: InstallationType.DELTA,
	installationPeriod: InstallationPeriod.BEGINNING
};
