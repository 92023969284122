/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, FC } from 'react';

import { ErrorHandler, ErrorHandlerProps } from './ErrorHandler';

export const withErrorHandler = <P = any>(Component: ComponentType<P>): FC<P & ErrorHandlerProps> => {
	return (props: any) => {
		const { errorComponent, transparent, ...componentProps } = props;
		return (
			<ErrorHandler errorComponent={errorComponent} transparent={transparent}>
				<Component {...componentProps} />
			</ErrorHandler>
		);
	};
};
