import { Nullable } from '@abb-emobility/shared/util';

import { StorageAdapter } from './StorageAdapter';

export class MemoryStorageAdapter implements StorageAdapter {

	private static storage: Map<string, string> = new Map();

	read(key: string): Nullable<string> {
		return MemoryStorageAdapter.storage.get(key) ?? null;
	}

	write(key: string, value: string): void {
		MemoryStorageAdapter.storage.set(key, value);
	}

	remove(key: string): void {
		MemoryStorageAdapter.storage.delete(key);
	}

	clear(): void {
		MemoryStorageAdapter.storage.clear();
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	addListener(_callback: (event: StorageEvent) => void): void {
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	removeListener(_callback: (event: StorageEvent) => void): void {
	}

}
