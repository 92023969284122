import React, { ReactNode } from 'react';

import { useAuth } from '@abb-emobility/shared/auth-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	AppLayout,
	AppLayoutMain,
	AppLayoutMainContent,
	AppLayoutNavigation,
	IconIdentifier,
	NavigationFooter, NavigationItem,
	NavigationItems,
	NavigationSubItem
} from '@abb-emobility/shared/ui-primitive';

import { RoutePath } from '../../router/Routes';

export type AppShellProps = {
	children: ReactNode
};

export function AppShell(props: AppShellProps) {

	const { children } = props;

	const auth = useAuth();
	const l10n = useL10n();

	const handleLogout = () => {
		auth.unauthenticate();
	};

	return (
		<AppLayout>
			<AppLayoutNavigation>
				<NavigationItems>
					<NavigationItem
						to={RoutePath.B2C}
						icon={IconIdentifier.CAR_SIMPLE}
						label={l10n.translate('omsOrderApp.navigation.b2c')}
						highlightPaths={[RoutePath.ROOT, RoutePath.B2C]}
					/>
					<NavigationItem
						to={RoutePath.B2B}
						icon={IconIdentifier.BANK}
						label={l10n.translate('omsOrderApp.navigation.b2b')}
						highlightPaths={[RoutePath.B2B]}
					/>
				</NavigationItems>
				<NavigationFooter>
					<NavigationSubItem
						onClick={handleLogout}
						icon={IconIdentifier.SIGN_OUT}
						label={l10n.translate('omsOrderApp.navigation.logout')}
					/>
				</NavigationFooter>
			</AppLayoutNavigation>
			<AppLayoutMain>
				<AppLayoutMainContent>
					{children}
				</AppLayoutMainContent>
			</AppLayoutMain>
		</AppLayout>
	);
}
