import { Component, ReactNode } from 'react';

import { Logger, LogLevel, withLogger } from '@abb-emobility/shared/logger';

export type SilentErrorHandlerProps = {
	handleError: (error: Error) => boolean,
	transparent?: boolean,
	logger?: Logger,
	children?: ReactNode
};

type ErrorHandlerState = {
	error?: Error
};

class SilentErrorHandlerFoundation extends Component<SilentErrorHandlerProps, ErrorHandlerState> {

	constructor(props: SilentErrorHandlerProps) {
		super(props);
		this.state = {
			error: undefined
		};
	}

	static getDerivedStateFromError(error: Error): ErrorHandlerState {
		return {
			error: error
		};
	}

	private logError(error: Error) {
		if (!this.props.logger) {
			return;
		}
		void this.props.logger.logError(error, LogLevel.ERROR);
	}

	private renderError(error: Error) {
		const errorHandled = this.props.handleError(error);
		if (!errorHandled) {
			throw error;
		}
		const children = this.props.transparent ? this.props.children : null;
		return children;
	}

	override render() {
		const error = this.state.error;
		if (error !== undefined) {
			this.logError(error);
			return this.renderError(error);
		}
		return this.props.children;
	}

}

export const SilentErrorHandler = withLogger(SilentErrorHandlerFoundation);
