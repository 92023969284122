import React, { useEffect } from 'react';

import { OrderImportB2bModel } from '@abb-emobility/oms/domain-model';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreationDataProviderValue,
	CreationDataProviderProps
} from '@abb-emobility/shared/data-provider-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError, withErrorHandler } from '@abb-emobility/shared/error';
import { Optional } from '@abb-emobility/shared/util';

import { orderImportB2bCreationData } from './OrderImportB2bCreationData';
import {
	OrderImportB2bCreationStore,
	orderImportB2bEntityStoreAccessors,
	orderImportB2bCreationStoreName
} from './OrderImportB2bCreationSlice';

export function OrderImportB2bCreationDataProvider(props: CreationDataProviderProps) {
	const auth = useAuth();
	const env = useEnv();

	const apiBaseUrl = new Optional(process.env['NX_ORDER_IMPORT_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCreationDataProviderValue<
		OrderImportB2bModel, OrderImportB2bCreationStore
	>(
		orderImportB2bCreationStoreName,
		orderImportB2bEntityStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useEffect(() => {
		dataProviderValue.resolveActionStatus();
	}, []);

	return (
		<orderImportB2bCreationData.Provider value={dataProviderValue}>
			{props.children}
		</orderImportB2bCreationData.Provider>
	);
}

export const OrderImportB2bCreationDataProviderWithErrorHandler = withErrorHandler(
	OrderImportB2bCreationDataProvider
);
