import { CustomerOrderInformationOrderItemsInformationModel } from '@abb-emobility/oms/domain-model';
import { CurrencyCode } from '@abb-emobility/shared/currency';
import { createTimestampDtoFromDate, Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerOrderInformationOrderItemsInformationLineItemModelMock01,
	customerOrderInformationOrderItemsInformationLineItemModelMock02,
	customerOrderInformationOrderItemsInformationLineItemModelMock03
} from './CustomerOrderInformationOrderItemsInformationLineItemModel.mock';

export const customerOrderInformationOrderItemsInformationModelMock: Dto<CustomerOrderInformationOrderItemsInformationModel> = {
	orderLineItems: [
		customerOrderInformationOrderItemsInformationLineItemModelMock01,
		customerOrderInformationOrderItemsInformationLineItemModelMock02,
		customerOrderInformationOrderItemsInformationLineItemModelMock03
	],
	wallboxSerialNumber: '352645856846',
	currencyCode: CurrencyCode.EUR,
	marketplaceName: 'Smart Marketplace Germany',
	orderDate: createTimestampDtoFromDate(new Date('2024-02-29T12:31:21Z')),
	totalGrossPrice: 1234567
};
