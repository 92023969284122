import { Optional } from '@abb-emobility/shared/util';

import { BrowserLocalStorageAdapter } from './storage-adapter/BrowserLocalStorageAdapter';
import { StorageAdapter } from './storage-adapter/StorageAdapter';

export type ValueWriteConversion<From, To> = (value: From) => To;
export type ValueReadConversion<From, To> = (value: From) => To;

export class LocalStorage {

	private static storageAdapter: StorageAdapter = new BrowserLocalStorageAdapter();

	public static setStorageAdapter(storageAdapter: StorageAdapter) {
		this.storageAdapter = storageAdapter;
	}

	public static read<T, StorageT = T>(key: string, options?: { namespace?: string, conversion?: ValueReadConversion<StorageT, T> }): Optional<T> {
		const { namespace, conversion } = options ?? {};
		const value = this.storageAdapter.read(this.buildKey(key, namespace));
		if (value === null) {
			return new Optional<T>(null);
		}
		let data = JSON.parse(value);
		if (conversion !== undefined) {
			data = conversion(data);
		}
		return new Optional<T>(data);
	}

	public static write<T, StorageT = T>(key: string, value: T, options?: { namespace?: string, conversion?: ValueWriteConversion<T, StorageT> }): void {
		const { namespace, conversion } = options ?? {};
		let data;
		if (conversion !== undefined) {
			data = conversion(value);
		} else {
			data = value;
		}
		this.storageAdapter.write(this.buildKey(key, namespace), JSON.stringify(data));
	}

	public static remove(key: string, options?: { namespace?: string }): void {
		const { namespace } = options ?? {};
		this.storageAdapter.remove(this.buildKey(key, namespace));
	}

	public static clear(): void {
		this.storageAdapter.clear();
	}

	public static addListener(callback: (event: StorageEvent) => void): void {
		this.storageAdapter.addListener(callback);
	}

	public static removeListener(callback: (event: StorageEvent) => void): void {
		this.storageAdapter.removeListener(callback);
	}

	private static buildKey(key: string, namespace?: string): string {
		return (namespace ? namespace + '_' : '') + key;
	}
}
