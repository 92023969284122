import React from 'react';

import { OrderImportB2bCreationDataProviderWithUiErrorHandler } from '@abb-emobility/oms/ui-data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { ViewHeader } from '@abb-emobility/shared/ui-primitive';

import { OrderB2bForm } from '../../components/order-form/OrderB2bForm';
import { useBuildPageTitle } from '../../components/page-title/PageTitle.hook';

export function OrderB2bView() {

	const l10n = useL10n();

	usePageTitle(useBuildPageTitle('omsOrderApp.pageTitle.orderB2b'));

	return (
		<>
			<ViewHeader heading={l10n.translate('omsOrderApp.pageTitle.orderB2b')} />
			<OrderImportB2bCreationDataProviderWithUiErrorHandler>
				<OrderB2bForm />
			</OrderImportB2bCreationDataProviderWithUiErrorHandler>
		</>
	);
}
