import React from 'react';

import { InstallationPartnerOrderModel } from '@abb-emobility/oms/domain-model';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError, withErrorHandler } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { installationPartnerOrderCollectionData } from './InstallationPartnerOrderCollectionData';
import {
	InstallationPartnerOrderCollectionStore,
	installationPartnerOrderCollectionStoreAccessors,
	installationPartnerOrderCollectionStoreName
} from './InstallationPartnerOrderCollectionSlice';

export function InstallationPartnerOrderCollectionDataProvider(props: CollectionDataProviderProps<InstallationPartnerOrderModel>) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_WALLBOX_INSTALLATION_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<
		InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore
	>(
		installationPartnerOrderCollectionStoreName,
		installationPartnerOrderCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(
		dataProviderValue, new FetchError(l10n.translate('omsDataProvider.error.orderCollectionFetchMessage'))
	);

	const initialSortCriteria = props.sortCriteria;
	const initialFilterCriteria = props.filterCriteria;
	const sortCriteria = dataProviderValue.querySortCriteria();
	const filterCriteria = dataProviderValue.queryFilterCriteria();
	const effectiveSortCriteria = sortCriteria ?? initialSortCriteria;
	const effectiveFilterCriteria = filterCriteria ?? initialFilterCriteria;
	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(effectiveSortCriteria, effectiveFilterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<installationPartnerOrderCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</installationPartnerOrderCollectionData.Provider>
	);

}

export const InstallationPartnerOrderCollectionDataProviderWithErrorHandler = withErrorHandler(
	InstallationPartnerOrderCollectionDataProvider
);
