import React from 'react';

import { FooModel } from '@abb-emobility/oms/domain-model';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { withErrorHandler } from '@abb-emobility/shared/error';

import { fooCollectionData } from './FooCollectionData';
import { fooCollectionStoreAccessors, FooCollectionStore, fooCollectionStoreName } from './FooCollectionSlice';

export function FooCollectionDataProvider(props: CollectionDataProviderProps<FooModel>) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch
	} = props;

	const auth = useAuth();
	const env = useEnv();

	const dataProviderValue = createCrudCollectionDataProviderValue<FooModel, FooCollectionStore>(
		fooCollectionStoreName,
		fooCollectionStoreAccessors,
		'https://foo.bar/',
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError('Error fetching foo'));

	const initialSortCriteria = props.sortCriteria;
	const initialFilterCriteria = props.filterCriteria;
	const sortCriteria = dataProviderValue.querySortCriteria();
	const filterCriteria = dataProviderValue.queryFilterCriteria();
	const effectiveSortCriteria = sortCriteria ?? initialSortCriteria;
	const effectiveFilterCriteria = filterCriteria ?? initialFilterCriteria;
	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(effectiveSortCriteria, effectiveFilterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<fooCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</fooCollectionData.Provider>
	);

}

export const FooCollectionDataProviderWithErrorHandler = withErrorHandler(FooCollectionDataProvider);
