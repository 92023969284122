/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, FC } from 'react';

import { UiErrorHandler, UiErrorHandlerProps } from './UiErrorHandler';

type ComponentProps = UiErrorHandlerProps & any;

export const withUiErrorHandler = <P = ComponentProps>(Component: ComponentType<P>): FC<P> => {
	return (props: any) => {
		return (
			<UiErrorHandler>
				<Component {...props} />
			</UiErrorHandler>
		);
	};
};
