import { OrderImportInstallationAddressModel } from '@abb-emobility/oms/domain-model';
import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';

const orderImportInstallationAddressHamburg: OrderImportInstallationAddressModel = {
	id: '1',
	street: 'Fuhlsbüttler Str. 527',
	city: 'Hamburg',
	region: 'Hamburg',
	country: IsoCountryCode.DE,
	postalCode: '22337',
	taxMatrix: {
		b2c: {
			goods: 19,
			goodsAndServices: 19
		},
		b2b: {
			goods: 19,
			goodsAndServices: 0
		}
	}
};

const orderImportInstallationAddressHelogoland: OrderImportInstallationAddressModel = {
	id: '2',
	street: 'Dänenstraße 1',
	city: 'Helgoland',
	region: 'Helgoland',
	country: IsoCountryCode.DE,
	postalCode: '27498',
	taxMatrix: {
		b2c: {
			goods: 0,
			goodsAndServices: 0
		},
		b2b: {
			goods: 0,
			goodsAndServices: 0
		}
	}
};

const orderImportInstallationAddressInvalid: OrderImportInstallationAddressModel = {
	id: '3',
	street: 'Fehlerstrasse',
	city: 'Fehlerhausen',
	region: 'Schleswig-Holstein',
	country: IsoCountryCode.DE,
	postalCode: '24111',
	taxMatrix: {
		b2c: {
			goods: 0,
			goodsAndServices: 0
		},
		b2b: {
			goods: 0,
			goodsAndServices: 0
		}
	}
};

export const orderImportInstallationAddresses: Array<OrderImportInstallationAddressModel> = [
	orderImportInstallationAddressHamburg,
	orderImportInstallationAddressHelogoland,
	orderImportInstallationAddressInvalid
];
