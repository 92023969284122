import { Nullable } from '@abb-emobility/shared/util';

import { StorageAdapter } from './StorageAdapter';

export class BrowserLocalStorageAdapter implements StorageAdapter {

	read(key: string): Nullable<string> {
		return window.localStorage.getItem(key) ?? null;
	}

	write(key: string, value: string): void {
		window.localStorage.setItem(key, value);
	}

	remove(key: string): void {
		window.localStorage.removeItem(key);
	}

	clear(): void {
		window.localStorage.clear();
	}

	addListener(callback: (event: StorageEvent) => void): void {
		window.addEventListener('storage', callback);
	}

	removeListener(callback: (event: StorageEvent) => void): void {
		window.removeEventListener('storage', callback);
	}

}
