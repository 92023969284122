import { AnyAction, Middleware } from '@reduxjs/toolkit';
import { metrics, Span } from '@sentry/react';

import { upperCase } from '@abb-emobility/shared/util';

import { endSentrySpan, startSentrySpan } from '../util/SentrySpan';

const runningSpans = new Map<string, Span>();

export const sentrySpanManagementMiddleware: Middleware = (_storeAPI) => {
	return (next) => {
		return async (action: AnyAction) => {
			if (action.meta?.requestId) {
				const transactionId = action.meta?.requestId;
				if (action.meta?.requestStatus === 'pending') {
					const transactionType = action.type.split('/');
					transactionType.pop();
					const transactionActionName = transactionType.pop();
					const transactionName = upperCase(transactionActionName) + ' ' + transactionType.join('/');
					const span = await startSentrySpan(transactionName, 'redux');
					if (span) {
						runningSpans.set(transactionId, span);
					}
				} else if (action.meta?.requestStatus === 'fulfilled') {
					const span = runningSpans.get(transactionId);
					if (span) {
						endSentrySpan(span);
					}
				}
			}
			return next(action);
		};
	};
};

const runningTransactions = new Map<string, number>();

export const sentryPerformanceMiddleware: Middleware = (_storeAPI) => {
	return (next) => {
		return (action: AnyAction) => {
			if (action.meta?.requestId) {
				const transactionId = action.meta?.requestId;
				if (action.meta?.requestStatus === 'pending') {
					runningTransactions.set(transactionId, performance.now());
				} else if (action.meta?.requestStatus === 'fulfilled') {
					const transactionStart = runningTransactions.get(transactionId);
					if (transactionStart) {
						const transactionDuration = performance.now() - transactionStart;
						const transactionType = action.type.split('/');
						transactionType.pop();
						const transactionActionName = transactionType.pop();
						metrics.distribution(
							'thunk.runtime',
							transactionDuration,
							{
								unit: 'millisecond',
								tags: {
									operation: upperCase(transactionActionName),
									target: transactionType.join('/')
								}
							}
						);
					}
				}
			}
			return next(action);
		};
	};
};
