import { InstallationPartnerFiscalReportModel, InstallationPartnerFiscalReportState } from '@abb-emobility/oms/domain-model';
import { CurrencyCode } from '@abb-emobility/shared/currency';
import {
	DownloadFileDto,
	createDownloadFileDtoFromDownloadFile,
	createTimestampDtoFromDate, createTimestampFromDate,
	DownloadFile,
	Dto
} from '@abb-emobility/shared/domain-model-foundation';
import { getToday } from '@abb-emobility/shared/util';

const currentMonth = getToday();
currentMonth.setDate(1);

const downloadFile: DownloadFile = {
	discriminator: 'DownloadFile',
	url: {
		discriminator: 'Url',
		accessible: true,
		value: 'https://fiege.com'
	},
	meta: {
		title: 'Pink',
		mimeType: 'image/png',
		fileName: 'pink.png',
		size: 70,
		lastModifiedDate: createTimestampFromDate(new Date('2023-12-15T10:00:00Z'))
	}
};
const downloadFileDto: DownloadFileDto = createDownloadFileDtoFromDownloadFile(downloadFile).getOrThrow(new Error('Creating API file failed'));

export const installationPartnerFiscalReportModelMock: Dto<InstallationPartnerFiscalReportModel> = {
	id: '1',
	month: createTimestampDtoFromDate(currentMonth),
	state: InstallationPartnerFiscalReportState.CURRENT,
	installationCount: Math.round(Math.random() * 100000),
	netRevenue: Math.round(Math.random() * 1000000000),
	netRevenueCurrency: CurrencyCode.EUR
};

export const installationPartnerFiscalReportCollectionMock = (): ReadonlyArray<Dto<InstallationPartnerFiscalReportModel>> => {
	const models = [];
	for (let i = currentMonth.getMonth(); i > 0; i--) {
		const month = getToday();
		month.setMonth(i);
		month.setDate(1);
		const state = month.getMonth() === currentMonth.getMonth() ? InstallationPartnerFiscalReportState.CURRENT : InstallationPartnerFiscalReportState.CLOSED;
		const download = month.getMonth() === currentMonth.getMonth() ? undefined : downloadFileDto;
		models.push({
			...installationPartnerFiscalReportModelMock,
			id: i.toString(),
			month: createTimestampDtoFromDate(month),
			installationCount: Math.round(Math.random() * 100000),
			netRevenue: Math.round(Math.random() * 1000000000),
			state,
			download
		});
	}
	return models;
};
