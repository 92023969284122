import React, { useEffect } from 'react';

import { OrderImportB2cModel } from '@abb-emobility/oms/domain-model';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreationDataProviderValue,
	CreationDataProviderProps
} from '@abb-emobility/shared/data-provider-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError, withErrorHandler } from '@abb-emobility/shared/error';
import { Optional } from '@abb-emobility/shared/util';

import { orderImportB2cCreationData } from './OrderImportB2cCreationData';
import {
	OrderImportB2cCreationStore,
	orderImportB2cEntityStoreAccessors,
	orderImportB2cCreationStoreName
} from './OrderImportB2cCreationSlice';

export function OrderImportB2cCreationDataProvider(props: CreationDataProviderProps) {
	const auth = useAuth();
	const env = useEnv();

	const apiBaseUrl = new Optional(process.env['NX_ORDER_IMPORT_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCreationDataProviderValue<
		OrderImportB2cModel, OrderImportB2cCreationStore
	>(
		orderImportB2cCreationStoreName,
		orderImportB2cEntityStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useEffect(() => {
		dataProviderValue.resolveActionStatus();
	}, []);

	return (
		<orderImportB2cCreationData.Provider value={dataProviderValue}>
			{props.children}
		</orderImportB2cCreationData.Provider>
	);
}

export const OrderImportB2cCreationDataProviderWithErrorHandler = withErrorHandler(
	OrderImportB2cCreationDataProvider
);
