import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { LocalStorage } from '@abb-emobility/shared/local-storage';

import { authContext } from './AuthContext';
import { AuthHandler } from './AuthHandler';
import { AccessToken, RefreshToken } from '../foundation/JsonWebToken';

export type AuthProviderInterceptingProps = {
	onAuthenticate?: (
		accessToken: AccessToken,
		accessTokenValidTo: Date,
		refreshToken?: RefreshToken,
		refreshTokenValidTo?: Date,
		roles?: Array<string>
	) => void
};

type InterceptingProps = | {
	interceptRendering: false,
	interceptingComponent?: never
} | {
	interceptRendering?: true,
	interceptingComponent: () => ReactElement<AuthProviderInterceptingProps>
};

export type AuthProviderProps = {
	children: ReactNode,
	authScope?: string,
	onAuthenticate?: () => void,
	onUpdate?: () => void,
	onReauthenticate?: () => void,
	onUnauthenticate?: () => void
} & InterceptingProps;

export const AuthProvider = (props: AuthProviderProps): React.JSX.Element => {
	const {
		children,
		authScope,
		onAuthenticate = null,
		onUpdate = null,
		onReauthenticate = null,
		onUnauthenticate = null,
		interceptRendering = true,
		interceptingComponent = null
	} = props;

	const authHandler = AuthHandler.get(authScope);

	const [authorized, setAuthorized] = useState<boolean>(authHandler.isAuthenticated());
	const [unauthorized, setUnauthorized] = useState<boolean>(false);

	useEffect(() => {
		LocalStorage.addListener(handleStorageChange);
		return () => {
			LocalStorage.removeListener(handleStorageChange);
		};
	}, []);

	const handleStorageChange = () => {
		const authenticated = authHandler.isAuthenticated();
		if (authorized && !authenticated && onUnauthenticate !== null) {
			onUnauthenticate();
		} else if (!authorized && authenticated && onAuthenticate !== null) {
			onAuthenticate();
		}
		setAuthorized(authenticated);
	};

	const authContextValue = {
		getScope: (): string | undefined => {
			return authHandler.getScope();
		},
		authenticate: (
			accessToken: AccessToken,
			accessTokenValidTo: Date,
			refreshToken?: RefreshToken,
			refreshTokenValidTo?: Date,
			roles?: Array<string>
		): void => {
			authHandler.authenticate(accessToken, accessTokenValidTo, refreshToken, refreshTokenValidTo, roles);
			setAuthorized(true);
			if (onAuthenticate !== null) {
				onAuthenticate();
			}
		},
		update: (accessToken: AccessToken, accessTokenValidTo: Date, refreshToken?: RefreshToken, refreshTokenValidTo?: Date): void => {
			authHandler.update(accessToken, accessTokenValidTo, refreshToken, refreshTokenValidTo);
			if (onUpdate !== null) {
				onUpdate();
			}
		},
		reauthenticate: (): void => {
			authHandler.unauthenticate();
			if (onReauthenticate !== null) {
				onReauthenticate();
			}
			setAuthorized(false);
		},
		unauthenticate: (): void => {
			if (onUnauthenticate !== null) {
				onUnauthenticate();
			}
			authHandler.unauthenticate();
			setUnauthorized(true);
		},
		getToken: authHandler.getToken,
		isAuthenticated: authHandler.isAuthenticated,
		needsUpdate: authHandler.needsUpdate,
		hasRole: authHandler.hasRole
	};

	if (interceptRendering && interceptingComponent !== null && !authHandler.isAuthenticated() && !unauthorized) {
		const preparedInterceptionComponent = React.cloneElement(interceptingComponent(), {
			onAuthenticate: (
				accessToken: AccessToken,
				accessTokenValidTo: Date,
				refreshToken?: RefreshToken,
				refreshTokenValidTo?: Date,
				roles?: Array<string>
			): void => {
				authContextValue.authenticate(
					accessToken,
					accessTokenValidTo,
					refreshToken,
					refreshTokenValidTo,
					roles
				);
			}
		});
		return (preparedInterceptionComponent);
	}

	return (
		<authContext.Provider value={authContextValue}>
			{children}
		</authContext.Provider>
	);

};
