export enum RoutePath {
	ROOT = '/',
	B2C = '/b2c/',
	B2B = '/b2b/'
}

export type RouteParameter = [string, string];

export const createRouteUrl = (routePath: RoutePath, ...parameters: Array<RouteParameter>): string => {
	let url = String(routePath);
	for (const parameter of parameters) {
		url = url.replace(':' + parameter[0], parameter[1]);
	}
	return url;
};
