import { Route } from 'react-router-dom';

import { useSentryRoutes } from '@abb-emobility/shared/sentry-integration';

import { RoutePath } from './Routes';
import { OrderB2bView } from '../views/order-view/OrderB2bView';
import { OrderB2cView } from '../views/order-view/OrderB2cView';

export function Router() {

	const Routes = useSentryRoutes();

	return (
		<Routes>
			<Route path={RoutePath.ROOT} element={<OrderB2cView />} />
			<Route path={RoutePath.B2C} element={<OrderB2cView />} />
			<Route path={RoutePath.B2B} element={<OrderB2bView />} />
		</Routes>
	);

}
