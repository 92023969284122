import React from 'react';

import { OrderImportB2cCreationDataProviderWithUiErrorHandler } from '@abb-emobility/oms/ui-data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { ViewHeader } from '@abb-emobility/shared/ui-primitive';

import { OrderB2cForm } from '../../components/order-form/OrderB2cForm';
import { useBuildPageTitle } from '../../components/page-title/PageTitle.hook';

export function OrderB2cView() {

	const l10n = useL10n();

	usePageTitle(useBuildPageTitle('omsOrderApp.pageTitle.orderB2c'));

	return (
		<>
			<ViewHeader heading={l10n.translate('omsOrderApp.pageTitle.orderB2c')} />
			<OrderImportB2cCreationDataProviderWithUiErrorHandler>
				<OrderB2cForm />
			</OrderImportB2cCreationDataProviderWithUiErrorHandler>
		</>
	);
}
