import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { omsDataProviderLiteralsEn } from '@abb-emobility/oms/data-provider';
import { omsUiDomainLiteralsEn } from '@abb-emobility/oms/ui-domain';
import { EnvProvider } from '@abb-emobility/shared/environment';
import { AuthenticationRequiredError, NotFoundError, ValidationError } from '@abb-emobility/shared/error';
import { L10n, L10nProvider, Locale } from '@abb-emobility/shared/localization-provider';
import { ConsoleLogger, Logger, LoggerProvider, LogLevel } from '@abb-emobility/shared/logger';
import { sentryConfig, SentryLogger, TagValue } from '@abb-emobility/shared/sentry-integration';
import { sharedUiAuthEmitterLiteralsEn } from '@abb-emobility/shared/ui-auth-emitter';
import { sharedUiPrimitiveLiteralsEn } from '@abb-emobility/shared/ui-primitive';

import App from './app/App';
import { environment } from './environments/environment';
import { default as appLiteralsEn } from './l10n/literals-en.json';
import { store } from './store/store';

const locales = [
	new Locale('en', [
		sharedUiAuthEmitterLiteralsEn,
		appLiteralsEn,
		sharedUiPrimitiveLiteralsEn,
		omsUiDomainLiteralsEn,
		omsDataProviderLiteralsEn,
		sharedUiAuthEmitterLiteralsEn
	]),
	new Locale('de', ['/literal-bundles/literal-bundle-de.json'])
];

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

// Set up logger instances to be used by ErrorHandler components
const loggers: Array<Logger> = [
	new ConsoleLogger(true, undefined, undefined)
];
if (process.env['NX_SENTRY_ENABLED'] === 'true') {
	loggers.push(new SentryLogger(
		sentryConfig(),
		true,
		(error, logLevel) => {
			return !(
				error instanceof ValidationError
				|| error instanceof NotFoundError
				|| error instanceof AuthenticationRequiredError
				|| logLevel < LogLevel.WARN
			);
		},
		undefined,
		undefined,
		() => {
			return new Map<string, TagValue>([
				['client.locale', L10n.selectedLocale()]
			]);
		}
	));
}

root.render(
	<StrictMode>
		<LoggerProvider loggers={loggers}>
			<BrowserRouter>
				<Provider store={store}>
					<EnvProvider env={environment}>
						<L10nProvider
							locales={locales}
							fallbackLocaleIdentifier={'en'}
						>
							<App />
						</L10nProvider>
					</EnvProvider>
				</Provider>
			</BrowserRouter>
		</LoggerProvider>
	</StrictMode>
);
