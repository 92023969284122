import { AnyAction, combineReducers, configureStore, MiddlewareArray } from '@reduxjs/toolkit';

import {
	orderImportB2bCreationSlice,
	orderImportB2bCreationStoreName,
	orderImportB2cCreationStoreName,
	orderImportB2cCreationSlice
} from '@abb-emobility/oms/data-provider';
import { sentryPerformanceMiddleware, sentrySpanManagementMiddleware } from '@abb-emobility/shared/sentry-integration';

const combinedReducer = combineReducers({
	[orderImportB2cCreationStoreName]: orderImportB2cCreationSlice.reducer,
	[orderImportB2bCreationStoreName]: orderImportB2bCreationSlice.reducer
});

export type RootState = ReturnType<typeof combinedReducer> | undefined;

export const UNAUTHENTICATE_SIGNAL = '@@unauth';

const rootReducer = (state: RootState, action: AnyAction) => {
	if (action.type === UNAUTHENTICATE_SIGNAL) {
		state = undefined;
	}
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let middleware: MiddlewareArray<any> = getDefaultMiddleware({
			immutableCheck: { warnAfter: 128 },
			serializableCheck: { warnAfter: 128 }
		});
		if (process.env['NX_SENTRY_ENABLED'] === 'true') {
			middleware = middleware.concat(sentrySpanManagementMiddleware).concat(sentryPerformanceMiddleware);
		}
		return middleware;
	}
});
