import { withSentryReactRouterV6Routing } from '@sentry/react';
import { Routes } from 'react-router-dom';

export const useSentryRoutes = () => {

	let RoutesElement;
	if (process.env['NX_SENTRY_ENABLED'] === 'true') {
		RoutesElement = withSentryReactRouterV6Routing(Routes);
	} else {
		RoutesElement = Routes;
	}
	return RoutesElement;

};
